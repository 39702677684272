




























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Answers, Question, Survey, SurveyTheme } from '../../models';
import { defaultItemTypes } from '../../defaults';

import SurveyScreen from './SurveyScreen.vue';
import ChoiceItemView from '../item/ChoiceItemView.vue';
import SingleChoiceItemView from '../item/SingleChoiceItemView.vue';
import MultipleChoiceItemView from '../item/MultipleChoiceItemView.vue';

/*
import ChoiceItem from '../items/ChoiceItem.vue';
import NPSItem from '../items/NPSItem.vue';
import ImageChoiceItem from '../items/ImageChoiceItem.vue';
import ShortTextItem from '../items/ShortTextItem.vue';
import LongTextItem from '../items/LongTextItem.vue';
import RatingItem from '../items/RatingItem.vue';
import SliderItem from '../items/SliderItem.vue';
import RangeItem from '../items/RangeItem.vue';
import RankingItem from '../items/RankingItem.vue';
*/
@Component({
  components: {
    SurveyScreen,
    ChoiceItemView,
    SingleChoiceItemView,
    MultipleChoiceItemView,
  },
})
export default class SurveyQuestion extends Vue {
  @Prop({ required: true })
  survey!: Survey;

  @Prop({ required: true })
  theme!: SurveyTheme;

  @Prop({ required: true })
  question!: Question;

  @Prop({ default: 0 })
  questionIndex!: number;

  questionTypes = defaultItemTypes;
  answers: Answers = [];

  get surveyProgress(): number {
    return this.survey && this.survey.questions ? (100 * (this.questionIndex + 1)) / this.survey.questions.length : 0;
  }
  get currentQuestion(): Question | null {
    return this.survey && this.survey.questions ? this.survey.questions[this.questionIndex] : null;
  }
  get questionNumber(): number {
    return this.questionIndex + 1;
  }
  get lastQuestion(): boolean {
    return this.survey && this.survey.questions ? this.questionNumber === this.survey.questions.length : false;
  }
  get canMoveForward(): boolean {
    if (!this.survey || !this.survey.questions || !this.survey.questions[this.questionIndex]) return false;
    return (
      this.question!.settings!.required === false ||
      (this.question!.settings!.required &&
        this.answers[this.questionIndex] !== null &&
        this.answers[this.questionIndex] !== undefined)
    );
  }
  get canMoveBack(): boolean {
    if (!this.survey || !this.survey.questions || !this.survey.questions[this.questionIndex]) return false;
    return this.questionIndex > 0;
  }

  previousQuestion() {
    if (!this.survey || this.survey.questions.length === 0) return;
    if (this.questionNumber > 1) {
      this.$router.push(`#${this.questionNumber - 1}`);
    }
  }
  nextQuestion() {
    if (!this.survey || this.survey.questions.length === 0) return;
    if (this.questionNumber < this.survey.questions.length) {
      this.$router.push(`#${this.questionNumber + 1}`);
    }
  }

  async submitSurvey() {
    if (!this.survey) return;
    this.$bus.$emit('survey-end', this.answers);
    this.$router.push(`#end`);
  }
}
